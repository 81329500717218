










































































































































































































import { Component, Watch } from 'vue-property-decorator';
import moment from 'moment-timezone';
import firebase from '@/plugins/firebase';
import BaseClass from '@/base';

type WorkExperience = {
    companyName: string;
    jobTitle: string;
    startDate: string | null;
    endDate: string | null;
    currentlyWorkHere: boolean;
    industry: string;
    specialization: string;
    positionLevel: string;
    salary: string;
};

type UploadStatus = 'Pending' | 'Uploading' | 'Error' | 'Uploaded';
@Component
export default class ScrapeJobseeker extends BaseClass {
    jobstreetTitle = '';
    previewDialog = false;
    previewSeeker = {};
    email = '';
    password = '';

    tokenDialog = false;
    token = '';
    uploading = false;
    doNotPreApply = false;
    preApplyToPosition = '';
    openPositions: firebase.firestore.DocumentData[] = [];
    importCount = 0;
    skillsList: string[] = [];
    seekers: {
        email: string;
        lastName: string;
        firstName: string;
        gender: string;
        age: number;
        birthDate: string;
        address: string;
        degree: string;
        about: string;
        accountType: 'job_seeker';
        displayName: string;
        educationalAttainment: string;
        github: string;
        linkedin: string;
        nickName: string;
        occupation: string;
        openForRemote: boolean;
        onlineResume: boolean;
        phoneNumber: string;
        preferredJobType: 'Full Time';
        preferredShift: 'Day Shift';
        setupDone: boolean;
        skills: {
            text: string;
            rating: number;
            years: number;
        }[];

        skillsArray: string[];

        workExperience: WorkExperience[];
        status: UploadStatus;
        password: string;
        errorMessage: string | null;
    }[] = [];
    seekersError = [];

    config: firebase.firestore.DocumentData = {};
    configLoaded = false;

    jobstreetPositions: {
        title: string;
        id: number;
    }[] = [];

    jobstreetPositionsLoaded = false;
    selectedJobstreetId: number | null = null;
    newToken = '';

    @Watch('selectedJobstreetId')
    onSelectedJobstreetIdChange(id: number) {
        const title = this.jobstreetPositions.find((i) => i.id === id)
            ?.title;
        if (title) {
            this.jobstreetTitle = title;
        }
    }

    @Watch('doNotPreApply')
    ondoNotPreApplyChanged(doNotPreApply: boolean) {
        if (doNotPreApply) {
            this.preApplyToPosition = '';
        }
    }

    @Watch('selectedJobstreetId')
    async onJobstreetIdChanged(id: number | null) {
        this.seekers = [];
        if (!id) {
            this.seekers = [];
            return;
        }
        this.loading = true;
        try {
            const getShortlistFn = this.firebase
                .app()
                .functions('asia-east2')
                .httpsCallable('jobstreet-getShortlist');

            const result = await getShortlistFn({
                jobId: this.selectedJobstreetId,
                token: this.token,
            });

            // console.log(
            //     result.data.profiles.filter(
            //         (x) =>
            //             x.personal?.email?.value ===
            //             'crazeebny@yahoo.com.ph'
            //     )
            // );

            this.seekers = result.data.profiles.map((x: any) => {
                const skillsOrig = [
                    ...x.skills?.skill_advanced,
                    ...x.skills?.skill_beginner,
                ];

                const skills = skillsOrig.filter((i) =>
                    this.skillsList.includes(i)
                );

                let age = null;
                let birthDate = null;

                if (
                    moment(x.personal?.birth_date.date)
                        .tz('Asia/Manila')
                        .format('YYYY') !== '1900'
                ) {
                    birthDate = moment(x?.personal?.birth_date?.date)
                        .tz('Asia/Manila')
                        .format('YYYY-MM-DD');
                    age = x.personal?.birth_date?.age;
                }

                const expectedSalary = x.preference?.expected_salary?.formatted
                    .split(' ')[1]
                    ?.replace(/\D/g, '');

                return {
                    email: x.personal?.email?.value || null,
                    lastName: x.basicProfile?.name?.last,
                    firstName: x.basicProfile?.name?.first,
                    gender:
                        x.personal?.gender?.name !== 'Not Specified'
                            ? x.personal?.gender?.name
                            : 'Unspecified',
                    age: age,
                    birthDate: birthDate,
                    //   address: x.personal?.address?.formatted,
                    about: x?.application_pitch || '',
                    accountType: 'job_seeker',
                    displayName: x?.basicProfile?.name?.formatted,
                    educationalAttainment:
                        x?.basicProfile?.highest_qualification?.name || '',
                    github: '',
                    linkedin: '',
                    nickName: x.basicProfile?.name?.first,
                    occupation:
                        x.basicProfile?.current_position?.position_title ||
                        '',
                    expectedSalary: expectedSalary,
                    countryOfNationality:
                        x?.personal?.nationality?.name || null,
                    openForRemote: true,
                    onlineResume: true,
                    phoneNumber: x.personal?.handphone?.formatted || '',
                    preferredJobType: 'Full Time',
                    preferredShift: 'Day Shift',
                    jobstreetTitle: this.jobstreetTitle,
                    setupDone: true,
                    skills: skills || [],
                    skillsImported: skillsOrig || [],
                    skillsArray: skills?.map((i) => i.text) || [],
                    education: x.educations?.map((i: any) => {
                        return {
                            course: i?.field_of_study?.name,
                            from: '',
                            graduationDate: moment(i?.graduation_date?.date)
                                .tz('Asia/Manila')
                                .format('YYYY'),
                            qualification: i?.qualification?.name,
                            schoolName: i?.college?.name,
                            to: '',
                        };
                    }),
                    workExperience:
                        x.work_experiences?.map((i: any) => {
                            const present =
                                moment(i?.left_date?.date)
                                    .tz('Asia/Manila')
                                    .format('YYYY') === '1900'
                                    ? true
                                    : false;

                            return {
                                companyName: i?.company?.name,
                                currentlyWorkHere: present,
                                startDate:
                                    moment(i?.joined_date?.date)
                                        .tz('Asia/Manila')
                                        .format('YYYY-MM') || '',
                                endDate: present
                                    ? null
                                    : moment(i?.left_date?.date)
                                          .tz('Asia/Manila')
                                          .format('YYYY-MM'),
                                industry: i?.industry?.name,
                                jobDescription: i?.work_description || '',
                                jobTitle: i?.position_title || '',
                                positionLevel:
                                    i?.position_level?.name || '',
                                salary: i?.salary?.formatted || 0,
                                specialization:
                                    i?.specialization?.name || '',
                            };
                        }) || [],

                    status: 'Pending',
                };
            });

            console.log(this.seekers);
        } catch (error) {
            this.snackMessage(error.message);
        }
        this.loading = false;
    }

    @Watch('config')
    onConfigChanged(config: { jobstreetToken: string }) {
        if (config.jobstreetToken === this.token) {
            return;
        }
        this.token = config.jobstreetToken;
    }

    @Watch('token')
    async onTokenChanged(token: string) {
        if (!token) {
            return;
        }
        try {
            const loadPositionsFn = this.firebase
                .app()
                .functions('asia-east2')
                .httpsCallable('jobstreet-getAds');
            const result = await loadPositionsFn({
                token: this.token,
            });

            this.jobstreetPositions = result.data.json.data.map(
                (i: { position_title: string; job_id: number }) => {
                    return {
                        title: i.position_title,
                        id: i.job_id,
                    };
                }
            );

            this.jobstreetPositionsLoaded = true;
            this.newToken = this.token;
        } catch (error) {
            this.snackMessage(error.message);
        }
    }

    preview(seeker: firebase.firestore.DocumentData) {
        this.previewSeeker = seeker;
        this.previewDialog = true;
    }

    async upload() {
        this.uploading = true;

        for await (const seeker of this.seekers) {
            seeker.status = 'Uploading';
            try {
                // check if user exist
                const userExistRef = await this.firebase
                    .firestore()
                    .collection(`users`)
                    .where('email', '==', seeker.email)
                    .get();
                if (userExistRef.size > 0) {
                    throw new Error(`User already exists.`);
                }
                const jobAd = this.openPositions.find(
                    (i) => i.id === this.preApplyToPosition
                );

                const tempPassword = this.generatePassword();

                const userData = {
                    ...seeker,
                    firstName: seeker.firstName,
                    lastName: seeker.lastName,
                    accountType: 'job_seeker',
                    nickName: seeker.nickName,
                    phoneNumber: seeker.phoneNumber,
                    gender: seeker.gender,
                    email: seeker.email,
                    degree:
                        seeker.degree === `Bachelor's/College Degree`
                            ? `Bachelor's Degree`
                            : seeker.degree,
                    educationalAttainment: seeker.gender,
                    about: seeker.about,
                    age: seeker.age,
                    city: {
                        id: '',
                        name: '',
                    },
                    displayName: seeker.displayName,
                    github: '',
                    linkedin: '',
                    birthDate: seeker.birthDate,
                    occupation: seeker.occupation,
                    openForJob: true,
                    openForRemote: true,
                    photos: {
                        large: '',
                        medium: '',
                        original: '',
                        small: '',
                    },
                    preferredJobType: 'Full Time',
                    skillsArray: seeker.skills,
                    skills: seeker.skills.map((i: any) => {
                        return {
                            rating: 0,
                            text: i,
                            years: 0,
                        };
                    }),
                    tempPassword: tempPassword,
                    skillsComplete: seeker.skills,
                    setupDone: true,
                    workExperience: seeker.workExperience,
                    fromImport: true,
                    preApplyData:
                        jobAd === undefined
                            ? null
                            : {
                                  isJobAdOpen: jobAd.status === 'Open',
                                  jobAdId: this.preApplyToPosition,
                              },
                };
                delete userData.status;
                delete userData.errorMessage;

                const createFn = this.firebase
                    .app()
                    .functions('asia-east2')
                    .httpsCallable('admin-createUser');
                const result = await createFn({
                    email: seeker.email,
                    password: tempPassword,
                    displayName: seeker.displayName,
                    userData: userData,
                });

                // apply to position

                const uid = result.data.uid;
                if (!uid) {
                    throw new Error('Failed to get user ID.');
                }

                if (this.preApplyToPosition) {
                    const batch = this.firebase.firestore().batch();

                    const applicationRef = this.firebase
                        .firestore()
                        .collection(`applications`)
                        .doc();
                    batch.set(applicationRef, {
                        user: {
                            id: uid,
                            photo: '',
                        },
                        post: {
                            id: this.preApplyToPosition,
                        },
                        suppressEmailNotification: true,
                        status: 'Pending',
                        forceChangePassword: true,
                        forceScreeningQuestions: true,
                        noticePeriod: '',
                        employmentStatus: '',
                        coverLetter:
                            'System generated via Jobseeker Import',
                        screeningAnswers: [],
                        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                    });

                    batch.set(
                        this.firebase
                            .firestore()
                            .doc(
                                `users/${uid}/applications/${this.preApplyToPosition}`
                            ),
                        {
                            applicationId: applicationRef.id,
                        }
                    );

                    await batch.commit();
                }

                seeker.status = 'Uploaded';
            } catch (error) {
                seeker.status = 'Error';
                seeker.errorMessage = error.message;
            }
        }
        this.uploading = false;
    }

    async created() {
        this.$bind(
            'openPositions',
            this.firebase
                .firestore()
                .collection(`posts`)
                .orderBy('timestamp', 'desc')
        );

        this.$bind(
            'config',
            this.firebase.firestore().doc(`_meta_/config`)
        ).then(() => {
            this.configLoaded = true;
        });

        const skilsRef = await this.firebase
            .firestore()
            .doc(`_meta_/tags`)
            .get();

        this.skillsList = skilsRef.data()?.values;
    }

    async login() {
        this.loading = true;
        try {
            const loginFn = firebase
                .app()
                .functions('asia-east2')
                .httpsCallable('jobstreet-loginSiva');
            const result = await loginFn({
                email: this.email,
                password: this.password,
            });

            this.token = result.data.token;

            this.tokenDialog = false;
        } catch (error) {
            this.snackMessage(error.message);
        }
        this.loading = false;
    }

    private generatePassword() {
        return Math.random().toString(36).substr(2, 8);
    }
    deleteSeeker(index: number) {
        this.seekers.splice(index, 1);
    }
}
